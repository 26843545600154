.serviceList {
    text-align: left;
    max-width: 550px;
    font-size: 14px;
 }

.serviceList h1{
    max-width: 550px;
    font-size: 22px;
 }

.serviceList h2{
    text-align: left;
    max-width: 550px;
    font-size: 18px;
 }

.serviceList .card-img {
    width: 100%;
    height: 12vw;
    object-fit: cover;
}
