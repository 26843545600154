.MainColumn {
  margin: 5px;
  background-color: #d2e8ffa0;
  width: 670px;
  max-width: 95vw;
  min-height: 78.2vh;
  height: auto;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  color:var(--main-text-inverted);
  font-size: calc(10px + 0.7vmin);
}


.paginationContainer {
    margin: 5px;
}
