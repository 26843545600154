.whoweareText {
    text-align: left;
    display: flex;
    flex-direction: row;
    max-width: 550px;
    font-size: 14px;
 }

.whoweareText h1{
    max-width: 550px;
    font-size: 22px;
 }

.whoweareText h2{
    text-align: left;
    max-width: 550px;
    font-size: 18px;
 }
 
.about-page .page-top-pic .card-img {
    width: 100%;
    height: 20vw;
    object-fit: cover;
}


.about-page .card-img {
    height: 25vh;
    object-fit: contain;
}
/*
.about-page img.card-img {
    border-radius: 20%;
}

.about-page vignette {
	display: inline-block;
	border-radius: 20%;
}*/
