.Menu-container {
  margin: 2px;
  background-color: var(--shadow);
  width: 670px;
  max-width: 95vw;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 100;
}
.Menu-container>* {
  flex: 1 0 18%;
}
