.BlogPost {
  width: 98%;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--blog-post-background);
  text-align: left;
  color:var(--blog-post-text);
  border:solid;
  border-width:2px;
  border-color: var(--blog-post-border);
  box-shadow:2px 2px var(--shadow);
  margin-bottom: 10px;
}

.BlogPostFooter {
  text-align: right;
  color: var(--shadow);
  font-size: 0.7rem;
}
