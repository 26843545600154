.App {
  text-align: center;
  font-family: 'Arvo', serif;
}

.App-logo {
  height: 10vmin;
  min-height: 50px;
  filter: brightness(0) invert(1);
  pointer-events: none;
  margin: 10px
}

.App-header {
  background-color: var(--shadow);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: var(--main-text);
}

.App-body {
  background-color: #0000;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--main-text);
}

.App-footer {
  background-color: var(--shadow);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px);
  color: var(--main-text);

}


.App-link {
  color: var(--secondary-button);
}

.bgVideoLoop {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -10;
  object-fit: cover;
}
