/*#4169e1
#4191e1
#2d669d
#d2e8ff
#a4d2ff
*/
:root {
    --main-background:#06c;
    --main-background-inverted:#d2e8ff;
    --main-text-inverted:#000080;
    --secondary-background:#a4d2ff;
    --primary-button:#4191e1;
    --shadow:#111122;
    --main-text:#c0c0ff;
    --secondary-button:#a4d2ff;
    --blog-post-background:#eee;
    --blog-post-text:#111;
    --blog-post-border:#ddd;
}


.btn-primary {
           background-color: --primary-button;
           color: --main-text;
           border-color: --shadow;
        }

.btn-secondary {
           background-color: --secondary-button;
           color: --main-text;
           border-color: --shadow;
        }
