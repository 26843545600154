.contactsBody {
    text-align: left;
    max-width: 450px;
 }

.contacts {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    max-width: 550px;
    font-size: 14px;
 }


.contacts h1{
    font-size: 22px;
 }

.contacts h2{
    font-size: 18px;
 }

.contacts h3{
    font-size: 14px;
    text-align: left;
 }

.contacts .card-img {
    height: 15vh;
    object-fit: cover;
}
